import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";


const Grid = styled.div`
    background: linear-gradient(180deg, black, #052A35 50%, #0A5369);
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
  `}
  ${media.greaterThan("large")`
  `}
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
`; 

const ImgServices = styled.div`
    border: 3px black solid;
    outline: none!;
    z-index: 1;
    height: 260px;
    width: 400px;
  ${media.lessThan("medium")`
    height: 252px;
    width: 356px;
  `}
  ${media.between("medium", "large")`
    height: 300px;
    width: 426px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
    margin-right: 100px;
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 40px;
    z-index: 1;
    color: white;
    justify-self: center;
  ${media.lessThan("medium")`
    font-size: 1.2em;
    align-self: center;
  `}

  ${media.between("medium", "large")`
    font-size: 1.4em;
    align-self: center;
  `}
`;

const AboutText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    width: 500px;
    text-shadow: none;
    justify-self: center;
    font-size: 14pt;
    color: white;
    line-height: 38px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1em;
    margin-bottom: 30px;
    width: 300px;
    text-align: center;
    line-height: 28px;
    text-align: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.2em;
    width: 430px;
    margin-bottom: 40px;
    text-align: center; 
  `}
`;

export default class BannerPossibility extends Component {
    
        componentDidMount(){
            Aos.init({ duration: 2000 });
          }
          

  render() {
    return (
        <Grid>
          <TitleContainer data-aos="zoom-in">
            <Title>The Possibility</Title>
            <AboutText> Our vision is for Yama to be THE go-to service provider for apartment building 
                access & security across North America. Our vision is for Yama to be THE go-to service provider for 
                apartment building access & security across North America </AboutText>
          </TitleContainer>
          <LogoContainer data-aos="zoom-in">
            <ImgServices />
          </LogoContainer>
        </Grid>
    )
  }
}

// Image - 2 boxes

