import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Image2 from "./../../images/Apartment_Network.png";
import { Link } from 'react-router-dom'


const Background = styled.div`
 background: linear-gradient(180deg, #0A5369, #052A35 50%, black);
 align-content: center;
 height: auto;
 display: flex;
 flex-direction: column;
`;

const Container = styled.div`
    /* border: 1px red dashed; */
    grid-area: 5 / 1 / 6 / 2;
    height: auto;
    width: 80%;
    max-width: 1440px;
    justify-self: center;
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-bottom: 80px;
    justify-content: space-evenly;
    ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
  `}
  ${media.greaterThan("large")`
    flex-direction: row;
  `}
`;

const TitleContainer = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 450px;
  ${media.lessThan("medium")`
    align-self: center;
    width: auto;

  `}
  ${media.between("medium", "large")`
    align-self: center;
  `}
  ${media.greaterThan("large")`
    /* margin-right: 100px; */
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    z-index: 1;
    color: white;
    line-height: 30px;
    margin-bottom: 10px;
    align-self: center;
  ${media.lessThan("medium")`
    align-self: center;
    font-size: 1.2em;
    width: auto;
    text-align: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
    /* align-self: center; */
  `}
`;

const Title2 = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.4em;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 80px;
    line-height: 70px;
    z-index: 1;
    color: white;
    align-self: center;
    text-align: center;
  ${media.lessThan("medium")`
    margin-bottom: 80px;
    align-self: center;
    line-height: 30px;
    font-size: 1.6em;
    width: 90%;
  `}

  ${media.between("medium", "large")`
    font-size: 2em;
  `}
`;

const AboutText = styled.p`
    /* width: 450px; */
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14pt;
    color: white;
    line-height: 32px;
    text-align: center;
    /* width: 300px; */
    ${media.lessThan("medium")`
    align-self: center;
    justify-self: center;
    text-align: center;
    font-size: 1em;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: 0px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.2em;
    /* text-align: center; */
    margin-bottom: 10px;
    /* width: 600px; */
    margin-top: 0px;
  `}
`;

const ButtonContainer = styled.div`
  align-self: center;
  ${media.lessThan("medium")`
    margin-top: 10px;
    width: 200px;
`}
  ${media.between("medium", "large")`
    margin-top: 10px;
    width: 200px;
    /* align-self: center; */
`}
`;

const BtnLearnMore = styled.button`
    padding: 12px;
    outline: none;
    width: 200px;
    height: 50px;
    color: black;
    background: #6EFAFF;
    border: 2px solid #6EFAFF;
    border-radius: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 60px;
    margin-top: 20px;
    cursor: pointer; 
    transition-duration: 0.5s;
        &:hover{
          opacity: 0.9;
          transform: scale(1.05);
        }
    ${media.lessThan("medium")`
    align-self: center;
    width: 200px;
    margin-bottom: 80px;
  `}
  ${media.between("medium", "large")`
    width: 200px;
    align-self: center;
  `}
`;


export default class CustomerType extends Component {
  render() {
    return (
        <Background>
            <Title2
                    data-aos="fade-in" 
                    data-aos-duration="1000" 
                    data-aos-easing="ease-in-sine"
            >What can Yama do for you?</Title2>
                <Container>
                  <TitleContainer
                    data-aos="fade-in" 
                    data-aos-duration="1000" 
                    data-aos-easing="ease-in-sine"
                  >
                      <Title>Do you manage buildings? </Title>
                      <AboutText>
                        Yama applies innovative technology to optimize your building access.
                      </AboutText>
                      <ButtonContainer>
                        <Link to="/manager">
                          <BtnLearnMore>LEARN MORE</BtnLearnMore>
                        </Link>
                      </ButtonContainer>
                  </TitleContainer>
                  <TitleContainer
                        data-aos="fade-in" 
                        data-aos-duration="2000" 
                        data-aos-easing="ease-in-sine"
                  >
                      <Title>Are you a tenant?</Title>
                      <AboutText>
                          Yama makes your intercom work for you in ways you'll wonder how you did without before.
                      </AboutText>
                      <ButtonContainer>
                        <Link to="/tenant">
                          <BtnLearnMore>LEARN MORE</BtnLearnMore>
                        </Link>
                      </ButtonContainer>
                  </TitleContainer>
                </Container>
            </Background>

    );
  }
}
