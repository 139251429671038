import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Image2 from "./../../images/img_tenantLifeEasier.png";
import Aos from "aos";
import "aos/dist/aos.css";


const Grid = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    z-index: -1;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
  `}
  ${media.greaterThan("large")`
  `}
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom: 100px;
    justify-content: space-evenly;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const ImgMoving = styled.div`
    background-image: url(${Image2});
    background-size: cover;
    background-repeat: no-repeat;
    outline: none!;
    z-index: 1;
    height: 340px;
    width: 282px;
  ${media.lessThan("medium")`
  height: 315px;
    width: 261px;
    margin-bottom: 20px;
  `}
  ${media.between("medium", "large")`
  height: 340px;
    width: 282px;
  `}
`;


const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-bottom: 0px;
  ${media.lessThan("medium")`
    width: 300px;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    align-self: center;
  `}
  ${media.greaterThan("large")`
    align-self: center;
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.2em;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 60px;
    margin-top: 100px;
    z-index: 1;
    color: black;
    text-align: center;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.6em;
    width: 240px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.8em;
  `}
`;

const Title2 = styled.h4`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6em;
    width: 500px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 40px;
    z-index: 1;
    color: black;
    justify-self: center;
    align-self: center;
  ${media.lessThan("medium")`
    width: 280px;
    align-self: center;
    text-align: center;
    line-height: 30px;
    font-size: 1.2em;
    margin-top: 20px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
    text-align: center;
  `}
`;

const AboutText = styled.p`
    width: 500px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-shadow: none;
    justify-self: center;
    font-size: 14pt;
    color: black;
    line-height: 38px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1em;
    width: 300px;
    text-align: center;
    align-self: center;
    line-height: 28px;
`}
  ${media.between("medium", "large")`
    text-align: center;
    font-size: 1.1em;
    width: 430px;
`}
`;

export default class WhyYama extends Component {

        componentDidMount(){
            Aos.init({ duration: 2000 });
          }


  render() {
    return (
        <Grid>
            <Title
              data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
            >Why Yama?</Title>
          <Container>
            <LogoContainer
              data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
            >
              <ImgMoving />
            </LogoContainer>
            <TitleContainer
              data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
            >
              <Title2>Yama can make your life easier</Title2>
              <AboutText>
              Whether you want to go keyless, give your friends and family virtual keys,
              have a few friends over, or make your multi-person living situation a
              little more equitable, Yama has your back
              </AboutText>
            </TitleContainer>
          </Container>

        </Grid>
    )
  }
}

// Image - Buzzer with code
