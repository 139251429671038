import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import Image from "./../../images/img_repairmanTech.png";

const Grid = styled.div`
    background: white;
    grid-area: 4 / 1 / 5 / 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
  `}
  ${media.greaterThan("large")`
  `}
`;

const LogoContainer = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    justify-content: center;
    align-content: center;
    /* width: 190px; */
    height: 200px;
    width: 300px;
    align-self: center;
    ${media.lessThan("medium")`
    margin-bottom: 20px;
  `}
  ${media.between("medium", "large")`
  margin-bottom: 20px;
  `}
  ${media.greaterThan("large")`
  `}
`; 

const ImgRepairman = styled.div`
    background-image: url(${Image});
    background-size: cover;
    background-repeat: no-repeat;
    outline: none!;
    z-index: 1;
    height: 200px;
    width: 291px;
    align-self: center;
`;


const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
    margin-right: 100px;
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 40px;
    z-index: 1;
    color: black;
    justify-self: center;
  ${media.lessThan("medium")`
    font-size: 1.2em;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
    align-self: center;
  `}
`;

const AboutText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    width: 500px;
    text-shadow: none;
    justify-self: center;
    font-size: 14pt;
    color: black;
    line-height: 38px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1em;
    margin-bottom: 30px;
    width: 300px;
    text-align: center;
    line-height: 28px;
    text-align: center;
`}
  ${media.between("medium", "large")`
    font-size: 1.2em;
    width: 430px;
    margin-bottom: 40px;
    text-align: center;
`}
`;

export default class Operations extends Component {
    
        componentDidMount(){
            Aos.init({ duration: 2000 });
          }
          

  render() {
    return (
        <Grid>
          <LogoContainer
              data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
          >
            <ImgRepairman />
          </LogoContainer>
          <TitleContainer
              data-aos="fade-left" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
          >
            <Title>Manage Daily Operations</Title>
            <AboutText>Remotely provide access for service and repairs contractors, utilities, cleaners, and emergency crews.  </AboutText>
          </TitleContainer>

        </Grid>
    )
  }
}

// Image - 2 boxes

