import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Image2 from "./../../images/Apartment_Network.png";

const Container = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-top: 100px;
  ${media.lessThan("medium")`
    flex-direction: column-reverse;
  `}
  ${media.between("medium", "large")`
    flex-direction: column-reverse;
  `}
  ${media.greaterThan("large")`
    flex-direction: row;
  `}
`;

const TitleContainer = styled.div`
    width: 600px;
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: center;
  ${media.lessThan("medium")`
    width: 300px;
    margin-left: 0px;
  `}
  ${media.between("medium", "large")`
    margin-left: 0px;
  `}
  ${media.greaterThan("large")`
    margin-right: 100px;
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.2em;
    font-weight: 700;
    z-index: 1;
    color: black;
    line-height: 44px;
    margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 1.2em;
    width: 240px;
    text-align: center;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
    align-self: center;
    margin-top: 40px;
  `}
`;

const AboutText = styled.p`
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14pt;
    color: black;
    line-height: 24px;
  ${media.lessThan("medium")`
    justify-self: center;
    text-align: center;
    align-self: center;
    font-size: 1em;
    width: 300px;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: 0px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
    width: 600px;
    margin-top: 0px;
  `}
`;

const BtnLearnMore = styled.button`
    padding: 12px;
    outline: none;
    width: 200px;
    color: white;
    background: #0996C1;
    border: 2px solid #0996C1;
    border-radius: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
  ${media.lessThan("medium")`
    width: 200px;
    align-self: center;
`}
  ${media.between("medium", "large")`
    width: 200px;
    align-self: center;
`}
`;

const ImageContainer = styled.div`
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-right: 100px;
  ${media.lessThan("medium")`
    margin-right: 0px;
  `}
  ${media.between("medium", "large")`
    margin-right: 0px;
  `}

`; 

const BenefitsImage = styled.div`
    outline: none!;
    z-index: 1;
    height: 440px;
    width: 600px;
    align-self: center;
  ${media.lessThan("medium")`
    height: 206.5px;
    width: 300px;
    margin-bottom: 20px;
  `}
  ${media.between("medium", "large")`
    height: 276px;
    width: 400px;
    margin-bottom: 20px;
  `}
  ${media.greaterThan("large")`
  `}
`;

export default class BenefitsList extends Component {
  render() {
    return (
                <Container>
                    <TitleContainer>
                        <Title>Yama is the future of apartment success </Title>
                        <AboutText>
                        Yama adds smart features to the apartment or condo buzzer you already have. 
                        Get started quickly with nothing to install. Yama adds smart features to the apartment or condo 
                        buzzer you already have. Get started quickly with nothing to install.
                        Yama adds smart features to the apartment or condo buzzer you already have. Get started quickly 
                        with nothing to install. Yama adds smart features to the apartment or condo buzzer you already have. 
                        Get started quickly with nothing to install.  Get started quickly with nothing to install. 
                        Yama adds smart features to the apartment or condo buzzer you already have. 
                        Get started quickly with nothing to install.
                        </AboutText>
                        <BtnLearnMore>Learn More</BtnLearnMore>
                    </TitleContainer>
                    <ImageContainer>
                        <BenefitsImage/>
                    </ImageContainer>
                </Container>
    );
  }
}
