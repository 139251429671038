import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";

const OuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const Container = styled.div`
    grid-area: 6 / 1 / 7 / 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: center;
    align-content: center;
    z-index: -1;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    width: 500px;
    padding: 30px;
    margin-bottom: 60px;
  ${media.lessThan("medium")`
    width: 300px;
    padding: 0px;
    padding-bottom: 40px;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    width: 500px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
`;

const Icon1 = styled.div`
    border: 5px solid black;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    align-self: center;
    margin-right: 20px;
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    z-index: 1;
    color: black;
    line-height: 30px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.2em;
    text-align: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
  `}
`;

const Title2 = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.4em;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 80px;
    line-height: 70px;
    z-index: 1;
    color: black;
    align-self: center;
    text-align: center;
  ${media.lessThan("medium")`
    margin-bottom: 80px;
    align-self: center;
    line-height: 30px;
    font-size: 1.8em;
  `}
  ${media.between("medium", "large")`
    font-size: 2em;
  `}
`;

const AboutText = styled.li`
    width: auto;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 12pt;
    color: black;
    line-height: 32px;
  ${media.lessThan("medium")`
    font-size: 1em;
`}
  ${media.between("medium", "large")`
    font-size: 1.2em;
`}
`;

export default class ExtraFeatures extends Component {


    componentDidMount(){
            Aos.init({ duration: 2000 });
          }
      

  render() {
    return (
        <OuterContainer>
            <Title2>And even more features</Title2>
            <Container>
                    <SectionContainer>
                        <TitleContainer>
                          <Icon1/>
                          <Title>Local Yama Number</Title>
                        </TitleContainer>
                        <AboutText>Instantly change which phones your buzzer rings on. </AboutText>
                        <AboutText>Stop receiving calls while you’re on vacation or </AboutText>
                        <AboutText>temporarily add a guest or Airbnb renter.   </AboutText>
                        <AboutText>Instantly change which phones your buzzer rings on. </AboutText>
                        <AboutText>Stop receiving calls while you’re on vacation or </AboutText>
                        <AboutText>temporarily add a guest or Airbnb renter.   </AboutText>
                    </SectionContainer>
                    <SectionContainer>
                      <TitleContainer>
                        <Icon1/>
                        <Title>Use Multiple Phones</Title>
                      </TitleContainer>
                      <AboutText>Instantly change which phones your buzzer rings on. </AboutText>
                        <AboutText>Stop receiving calls while you’re on vacation or </AboutText>
                        <AboutText>temporarily add a guest or Airbnb renter.   </AboutText>
                        <AboutText>Instantly change which phones your buzzer rings on. </AboutText>
                        <AboutText>Stop receiving calls while you’re on vacation or </AboutText>
                        <AboutText>temporarily add a guest or Airbnb renter.   </AboutText>
                    </SectionContainer>
                    <SectionContainer>
                      <TitleContainer>
                        <Icon1/>
                        <Title>Instant Forwarding</Title>
                      </TitleContainer>
                        <AboutText>Instantly change which phones your buzzer rings on. </AboutText>
                        <AboutText>Stop receiving calls while you’re on vacation or </AboutText>
                        <AboutText>temporarily add a guest or Airbnb renter.   </AboutText>
                        <AboutText>Instantly change which phones your buzzer rings on. </AboutText>
                        <AboutText>Stop receiving calls while you’re on vacation or </AboutText>
                        <AboutText>temporarily add a guest or Airbnb renter.   </AboutText>
                    </SectionContainer>
            </Container>
        </OuterContainer>

    )
  }
}
