import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Image2 from "./../../images/Apartment_Network.png";
import { Link } from "react-router-dom";

const Container = styled.div`
    /* border: 1px red dashed; */
    grid-area: 1 / 1 / 2 / 2;
    height: 600px;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    justify-self: center;
  ${media.lessThan("medium")`
    flex-direction: column-reverse;
  `}
  ${media.between("medium", "large")`
    flex-direction: column-reverse;
  `}
  ${media.greaterThan("large")`
    flex-direction: row;
  `}
`;

const TitleContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${media.lessThan("medium")`
    width: 100%;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
    margin-right: 100px;
  `}
`;

const Title = styled.h3`
    width: 500px;
    font-family: 'Montserrat', sans-serif;
    font-size: 2em;
    font-weight: medium;
    z-index: 1;
    color: white;
    line-height: 40px;
  ${media.lessThan("medium")`
    text-align: center;
    font-size: 1.4em;
    width: 90%;
    align-self: center;
`}

${media.between("medium", "large")`
    font-size: 2em;
    text-align: center;
    align-self: center;
    width: 600px;
`}
${media.greaterThan("large")`
    line-height: 60px;
`}
`;

const Title2 = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.8em;
    font-weight: 600;
    margin-bottom: 10px;
    color: white;
    line-height: 40px;
  ${media.lessThan("medium")`
    text-align: center;
    font-size: 1.6em;
    width: 90%;
    align-self: center;
  `}

  ${media.between("medium", "large")`
    font-size: 2em;
    text-align: center;
    align-self: center;
  `}
`;

const AboutText = styled.p`
    width: 550px;
    margin-top: 30px;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14pt;
    color: white;
    line-height: 32px;
    align-self: center;
  ${media.lessThan("medium")`
    justify-self: center;
    text-align: center;
    font-size: 1em;
    width: 280px;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: 0px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
    width: 600px;
    margin-top: 0px;
  `}
`;

const BtnContact = styled.button`
    /* padding: 12px; */
    margin-top: 20px;
    outline: none;
    width: 250px;
    height: 60px;
    color: black;
    background: #6EFAFF;
    border: 2px solid #6EFAFF;
    border-radius: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer; 
    transition-duration: 0.5s;
        &:hover{
          opacity: 0.9;
          transform: scale(1.05);
        }
  ${media.lessThan("medium")`
    margin-top: 10px;
    width: 200px;
    align-self: center;
`}
  ${media.between("medium", "large")`
    margin-top: 10px;
    width: 200px;
    align-self: center;
`}
`;

const ButtonContainer = styled.div`
  ${media.lessThan("medium")`
    margin-top: 10px;
    width: 200px;
    align-self: center;
`}
  ${media.between("medium", "large")`
    margin-top: 10px;
    width: 200px;
    align-self: center;
`}
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const LandingImage = styled.div`
    background-image: url(${Image2});
    background-size: cover;
    background-repeat: no-repeat;
    outline: none!;
    z-index: 1;
    height: 340px;
    width: 500px;
    align-self: center;
  ${media.lessThan("medium")`
    height: 180px;
    width: 280px;
    margin-bottom: 20px;
  `}
  ${media.between("medium", "large")`
    height: 276px;
    width: 400px;
    margin-bottom: 20px;
  `}
  ${media.greaterThan("large")`
  `}
`;

export default class HomeLanding extends Component {
  render() {
    return (
      <Container>
        <TitleContainer
          data-aos="fade-right" 
          data-aos-duration="1000" 
          data-aos-easing="ease-in-sine"
        >
          <Title>Make your intercom smarter </Title>
          <Title2>Stop depending on keys</Title2>
          <AboutText>Let our cloud-based software answer the door for you. <br />No extra hardware required.</AboutText>
          <ButtonContainer>
            <Link to="/tellmemore">
              <BtnContact>LEARN MORE</BtnContact>
            </Link>
          </ButtonContainer>

        </TitleContainer>
        <ImageContainer
          data-aos="fade-in" 
          data-aos-duration="1000" 
          data-aos-easing="ease-in-sine"
        >
          <LandingImage />
        </ImageContainer>
      </Container>
    );
  }
}
