import React, { Component } from "react";
import styled from "styled-components";
import WhatYamaDo from "./../Managers/WhatYamaDo";
import ManagersLanding from "./../Managers/ManagersLanding";
import NoMoreKeys from "./../Managers/NoMoreKeys";
import Operations from "./../Managers/Operations";
import ProgramIntercom from "./../Managers/ProgramIntercom";
import Secure from "./../Managers/Secure";
import Hardware from "./../Managers/Hardware";
import Api from "./../Managers/Api";
import EnableTenants from "./../Managers/EnableTenants";
import TagManager from 'react-gtm-module'

import BannerTemplateBlueLeftStatic from "./../Managers/BannerTemplateBlueLeftStatic";
import BannerTemplateWhiteLeftStatic from "./../Managers/BannerTemplateWhiteLeftStatic";
import BannerTemplateWhiteRightFade from "../Managers/BannerTemplateWhiteRightFade";
import BannerTemplateBlueRightFade from "./../Managers/BannerTemplateBlueRightFade";

import TopFeatures from "./../Managers/TopFeatures";
import MoreFeatures from "./../Managers/MoreFeatures";
import ExtraFeatures from "./../Managers/ExtraFeatures";

const Grid = styled.div`
    display: grid;
    grid-template: auto auto auto auto / 100vw;
    overflow: hidden;
`;

const BackgroundColor = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    background: linear-gradient(180deg, black, #052A35 50%, #0A5369);
    z-index: -1;
`;

export default class ManagerLayout extends Component {

  componentDidMount(){
    TagManager.initialize(tagPageVisit)
  }

  render() {
    return (
      <Grid>
        <BackgroundColor />
        <ManagersLanding/>
        <WhatYamaDo/>
        <NoMoreKeys/>
        <Operations/>
        <ProgramIntercom/>
        <Secure/>
        <Hardware/>
        <Api/>
        <EnableTenants/>
        {/* <BannerTemplateBlueRightFade/>
        <BannerTemplateWhiteRightFade/>
        <TopFeatures/> */}
      </Grid>
    );
  }
}


const tagPageVisit = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'yama_manager_page_visit'
  }
}