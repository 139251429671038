import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import Image from "./../../images/img_bucketKeysOverflow.png";
import Image2 from "./../../images/img_Clock.png";
import Image3 from "./../../images/img_brain.png";
import Image4 from "./../../images/img_moneybag.png";

const Container = styled.div`
    /* border: 2px solid black; */
    border-radius: 30px;
    /* background: linear-gradient(180deg, #0A5369, black); */
    /* background: #0A5369; */
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: center;
    align-content: center;
    z-index: -1;
    /* margin-bottom: 100px; */
    /* margin-top: 100px; */
`;

const TitleContainer = styled.div`
    grid-template: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* width: 300px; */
    padding: 50px;
`;

const Icon1 = styled.div`
    /* border: 5px solid black; */
    height: 120px;
    width: 120px;
    border-radius: 100px;
    align-self: center;
    margin-bottom: 30px;
`;

const ImageContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    justify-content: center;
    align-self: center;
    height: 150px;
    width: 160px;
    margin-bottom: 20px;
`; 

const ImageContainer2 = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    justify-content: center;
    align-self: center;
    height: 150px;
    width: 190px;
    margin-bottom: 20px;
`; 

const ImageContainer3 = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    justify-content: center;
    align-self: center;
    height: 150px;
    width: 180px;
    margin-bottom: 20px;
`; 

const ImgBucketKeys = styled.div`
    background-image: url(${Image});
    background-size: cover;
    background-repeat: no-repeat;
    outline: none!;
    z-index: 1;
    height: 108px;
    width: 190px;
    align-self: center;
`;



const ImgClock = styled.div`
    background-image: url(${Image2});
    background-size: cover;
    background-repeat: no-repeat;
    outline: none!;
    z-index: 1;
    height: 100px;
    width: 100px;
    align-self: center;
`;

const ImgBrain = styled.div`
    background-image: url(${Image3});
    background-size: cover;
    background-repeat: no-repeat;
    outline: none!;
    z-index: 1;
    height: 110px;
    width: 120px;
    align-self: center;
`;

const ImgMoneyBag= styled.div`
    background-image: url(${Image4});
    background-size: cover;
    background-repeat: no-repeat;
    outline: none!;
    z-index: 1;
    height: 140px;
    width: 101px;
    padding: 10px;
    align-self: center;
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    z-index: 1;
    color: black;
    line-height: 30px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.2em;
    width: 240px;
    text-align: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
  `}
`;

const Title2 = styled(Title)`
      color: white;
`;

const AboutText = styled.p`
    width: 350px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12pt;
    color: black;
    line-height: 32px;
    align-self: center;
    text-align: center;
  ${media.lessThan("medium")`
    font-size: 1em;
    width: 90%;
    margin-bottom: 10px;
`}

  ${media.between("medium", "large")`
    font-size: 1.2em;
    margin-bottom: 10px;
`}
`;

const AboutText2 = styled(AboutText)`
    color: white;
`;

export default class CardsActions extends Component {


    componentDidMount(){
            Aos.init({ duration: 2000 });
          }


  render() {
    return (
            <Container>
                    <TitleContainer
                      data-aos="fade-up" 
                      data-aos-duration="1000" 
                      data-aos-easing="ease-in-sine"
                    >
                        {/* keys pouring out of a bucket */}
                        <ImageContainer2>
                          <ImgBucketKeys/>
                        </ImageContainer2>
                        <Title>Ditch the Bucket of Keys</Title>
                        <AboutText>You can leave that pile of keys in the office, switch to a knowledge-based entry system instead.</AboutText>
                    </TitleContainer>
                    <TitleContainer
                        data-aos="fade-up" 
                        data-aos-duration="2000" 
                        data-aos-easing="ease-in-sine"
                    >
                        {/* a clock */}
                        <ImageContainer>
                          <ImgClock/>
                        </ImageContainer>
                        <Title>Save Time</Title>
                        <AboutText>Reprogram your intercoms from wherever you are - never send a human out to manually re-program again.</AboutText>
                    </TitleContainer>
                    <TitleContainer
                      data-aos="fade-up" 
                      data-aos-duration="1000" 
                      data-aos-easing="ease-in-sine"
                    >
                        {/* a brain */}
                        <ImageContainer>
                          <ImgBrain/>
                        </ImageContainer>
                        <Title>Be More Efficient</Title>
                        <AboutText>Why learn how to manage many different intercom models when you can learn Yama once instead?</AboutText>
                    </TitleContainer>
                    <TitleContainer
                      data-aos="fade-up" 
                      data-aos-duration="2000" 
                      data-aos-easing="ease-in-sine"
                    >
                        {/* monaaayyy   maybe a bag, maybe stacked coins. maybe both. */}
                        <ImageContainer3>
                          <ImgMoneyBag/>
                        </ImageContainer3>
                        <Title>Increase Your Value</Title>
                        <AboutText>Give tenants and owners access to Yama's features and increase the attractiveness of your investment.</AboutText>
                    </TitleContainer>
            </Container>
    )
  }
}
