import React, {Component} from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Image from "./../../images/img_bannerTechnology3.png";
import Image2 from "./../../images/Apartment_Network.png";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
    /* border: 1px red dashed; */
    grid-area: 4 / 1 / 5 / 2;
    height: auto;
    width: 100%;
    max-width: 1440px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-content: center; */
    justify-self: center;
    align-self: center;
  ${media.lessThan("medium")`
    flex-direction: column-reverse;
    `}
  ${media.between("medium", "large")`
    flex-direction: column-reverse;
    `}
  ${media.greaterThan("large")`
    flex-direction: row;
    `}
`;

const TitleContainer = styled.div`
  /* border: 1px green dashed; */
    display: flex;
    flex-direction: column;
    height: 400px;
  ${media.lessThan("medium")`
    height: auto;
  `}
  ${media.between("medium", "large")`
    height: auto;
  `}
  ${media.greaterThan("large")`
    /* margin-right: 100px; */
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    z-index: 1;
    color: black;
    line-height: 30px;
    /* margin-bottom: 20px; */
    margin-top: 80px;
    /* align-self: center; */

  ${media.lessThan("medium")`
    font-size: 1.1em;
    margin-top: 60px;
    width: 240px;
    text-align: center;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
    align-self: center;
  `}
`;

const Title2 = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.8em;
    font-weight: 700;
    margin-top: 40px;
    line-height: 70px;
    z-index: 1;
    color: black;
    text-align: center;
  ${media.lessThan("medium")`
    align-self: center;
    line-height: 30px;
    font-size: 1.8em;
  `}
  ${media.between("medium", "large")`
    font-size: 2em;
  `}
`;


const List = styled.p`
    width: 400px;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14pt;
    color: black;
    line-height: 32px;
    align-self: center;
    margin-top: 40px;
  ${media.lessThan("medium")`
    justify-self: center;
    text-align: center;
    font-size: 0.9em;
    width: 90%;
    line-height: 28px;
    margin-top: 20px;
`}
  ${media.between("medium", "large")`
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
    width: 600px;
    margin-top: 20px;
`}
`;


const ImageContainer = styled.div`
    /* grid-area: 1 / 1 / 2 / 2; */
    background-image: url(${Image});
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center;
    height: 381px;
    width: 100%;
  ${media.lessThan("medium")`
    flex-direction: column;
    background-size: cover;
    height: 200px;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
    height: 400px;
    background-size: cover;
  `}
  ${media.greaterThan("large")`
  `}
`;

const BenefitsImage = styled.div`
    outline: none!;
    z-index: 1;
    background-image: url(${Image});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 481px;
    width: 100%;
    align-self: center;
  ${media.lessThan("medium")`
    height: 206.5px;
    width: 100%;
    /* margin-bottom: 20px; */
  `}
  ${media.between("medium", "large")`
    height: 276px;
    width: 100%;
    /* margin-bottom: 20px; */
  `}
  ${media.greaterThan("large")`
  `}
`;

export default class BenefitsList extends Component {
    render() {
        return (
            <OuterContainer>
                <ImageContainer
                  data-aos="fade-in" 
                  data-aos-duration="1000" 
                  data-aos-easing="ease-in-sine"
                />
                <Title2>The Technology</Title2>
                <Container>
                    <TitleContainer>
                        <Title>Your Existing Hardware</Title>
                        <List>
                            If your intercom uses standard telephone lines, chances are it's Yama-ready!
                            Enable Yama without having to install a single thing.
                        </List>
                    </TitleContainer>
                    <TitleContainer>
                        <Title>Our Cloud Infrastructure</Title>
                        <List>
                            Yama uses modern software principles to increase security and add convenience to your building.
                            Access Yama's online dashboard from anywhere on Earth to manage your building.
                        </List>
                    </TitleContainer>
                </Container>
            </OuterContainer>

        );
    }
}
