import React, { Component } from 'react';
import * as Bootstrap from "react-bootstrap";
import styled from "styled-components";
import media from "styled-media-query";
import Logo from "./../../images/Yama_Logo.png";
import { Link } from 'react-router-dom'

let HeaderLogoImage = styled.div`
    background: ${(props) => `url(${Logo})`};
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    height: 38px;
    width: 140px;
    margin-left: 60px;
    align-content: center;
  ${media.lessThan("medium")`
    margin-left: 10px;
  `}

  ${media.between("medium", "large")`
    margin-left: 10px;
  `}

  ${media.greaterThan("large")`
  `}
`;


const HeaderLogo = styled.div`
    align-self: center;
`;

const NavListContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-left: auto;
  ${media.lessThan("medium")`
    flex-direction: column;
    align-content: flex-end;
    text-align: right;
  `}
  ${media.between("medium", "large")`
  `}

  ${media.greaterThan("large")`
  `}
`;

const NavItemTitle = styled.p`
    font-family: 'Montserrat Subrayada', sans-serif;
    font-size: 1.2em;
    font-weight: medium;
    text-decoration: none;
    color: white;
    text-shadow: 2px 2px 8px black;
    margin-right: 70px;
  &:hover {
    /* text-shadow: 4px 4px 8px turquoise; */
  }
  ${media.lessThan("medium")`
    font-size: 1rem
    justify-self: flex-end;
    margin-top: 20px;
    margin-right: 0px;
  `}

  ${media.between("medium", "large")`
    font-size: 1.2rem
    margin-right: 40px;
  `}

  ${media.greaterThan("large")`
  `}
`;

export default class BootNav extends Component {

  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    };
  }

  setNavExpanded = (expanded) => {
    console.log("setNavExpanded", expanded)
    this.setState({ navExpanded: expanded });
  }

  setNavClose = () => {
    console.log("setNavClose")
    this.setState({ navExpanded: false });
  }

  render() {
    return (
      <div>
        <Bootstrap.Navbar collapseOnSelect onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className="navbar-custom" expand="lg" variant="dark" style={{ paddingBottom: "20px", paddingTop: "20px" }}>
          <Bootstrap.Navbar.Brand>
            <Link to="/">
              <HeaderLogoImage>
                <HeaderLogo />
              </HeaderLogoImage>
            </Link>
          </Bootstrap.Navbar.Brand>

          <Bootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Bootstrap.Navbar.Collapse id="responsive-navbar-nav">
            <Bootstrap.Nav className="mr-auto">
            </Bootstrap.Nav>

            <Bootstrap.Nav onClick={this.setNavClose}>
              <NavListContainer>
                <li>
                  <Link to="/manager">
                    <NavItemTitle>Managers</NavItemTitle>
                  </Link>
                </li>
                <li>
                  <Link to="/tenant">
                    <NavItemTitle>Tenants</NavItemTitle>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <NavItemTitle>Contact</NavItemTitle>
                  </Link>
                </li>
                <li>
                  <a href="https://app.getyama.ca/accounts/login/" target="_blank">
                    <NavItemTitle>Go To App</NavItemTitle>
                  </a>

                </li>

              </NavListContainer>
            </Bootstrap.Nav>
          </Bootstrap.Navbar.Collapse>
        </Bootstrap.Navbar>
      </div>
    )
  }
}
