import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import Image from "./../../images/icon_computer.png";
import Image2 from "./../../images/icon_phone.png";
import Image3 from "./../../images/icon_doorman.png";
import Image4 from "./../../images/icon_intercom.png";

const Container = styled.div`
    /* border: 1px red dashed; */
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    flex-wrap: wrap;
    max-width: 1440px;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: center;
    align-content: center;
    z-index: -1;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    width: 500px;
    padding: 30px;
    margin-bottom: 60px;
  ${media.lessThan("medium")`
    width: 300px;
    padding: 0px;
    padding-bottom: 40px;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    width: 500px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Icon1 = styled.div`
    background-image: url(${Image4});
    background-size: cover;
    background-repeat: no-repeat;
    height: 80px;
    width: 55px;
    align-self: center;
    margin-right: 20px;
    ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Icon2 = styled.div`
    background-image: url(${Image2});
    background-size: cover;
    background-repeat: no-repeat;
    height: 80px;
    width: 45px;
    align-self: center;
    margin-right: 20px;
`;

const Icon3 = styled.div`
    background-image: url(${Image3});
    background-size: cover;
    background-repeat: no-repeat;
    height: 80px;
    width: 70px;
    align-self: center;
    margin-right: 20px;
`;

const Icon4 = styled.div`
    background-image: url(${Image});
    background-size: cover;
    background-repeat: no-repeat;
    height: 60px;
    width: 80px;
    align-self: center;
    margin-right: 20px;
`;



const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    z-index: 1;
    color: black;
    line-height: 30px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.1em;
    text-align: center;
    margin-top: 10px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
  `}
`;

const AboutText = styled.li`
    width: auto;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 12pt;
    color: black;
    line-height: 32px;
  ${media.lessThan("medium")`
    font-size: 1em;
  `}
  ${media.between("medium", "large")`
    font-size: 1.2em;
  `}
`;

export default class CardsActions extends Component {


    componentDidMount(){
            Aos.init({ duration: 2000 });
          }


  render() {
    return (
            <Container>
                    <SectionContainer
                          data-aos="fade-down" 
                          data-aos-duration="1000" 
                          data-aos-easing="ease-in-sine"
                    >
                        <TitleContainer>
                          <Icon1/>
                          <Title>Use Access Codes</Title>
                        </TitleContainer>
                        <AboutText>Give your guests access codes to use with your intercom instead of being tied to your phone, waiting for them to buzz you</AboutText>
                    </SectionContainer>
                    <SectionContainer
                          data-aos="fade-down" 
                          data-aos-duration="1000" 
                          data-aos-easing="ease-in-sine"
                    >
                        <TitleContainer
                        >
                          <Icon2/>
                          <Title>Local Yama Number</Title>
                        </TitleContainer>
                        <AboutText>Instantly change which phones your buzzer rings on</AboutText>
                        <AboutText>Stop receiving calls while you’re on vacation or temporarily add a guest or Airbnb renter</AboutText>
                    </SectionContainer>
                    <SectionContainer
                          data-aos="fade-up" 
                          data-aos-duration="1000" 
                          data-aos-easing="ease-in-sine"
                    >
                      <TitleContainer>
                        <Icon3/>
                        <Title>Stop Playing Gate-keeper</Title>
                      </TitleContainer>
                        <AboutText>With Yama, you don't have to chose which housemate has to handle intercom calls</AboutText>
                        <AboutText>Yama lets your guest choose who they want to let know they've arrived</AboutText>
                    </SectionContainer>
                    <SectionContainer
                          data-aos="fade-up" 
                          data-aos-duration="1000" 
                          data-aos-easing="ease-in-sine">
                      <TitleContainer>
                        <Icon4/>
                        <Title>Manage Your Intercom Remotely</Title>
                      </TitleContainer>
                        <AboutText>Whether your phone number has changed or someone new has moved in, you'll be able to change which phone numbers Yama can call by loggin into the Yama dashboard</AboutText>
                    </SectionContainer>
            </Container>
    )
  }
}
