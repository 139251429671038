import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Aos from "aos";
import TenantLanding from "../Tenants/TenantLanding";
import VideoContainer from "../TellMeMore/VideoContainer";
import SurveyContainer from "../TellMeMore/SurveyContainer";
import TagManager from 'react-gtm-module'

const Grid = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* height: 100vh; */
    min-height: 500px;
    /* padding-bottom: 100px; */
    background: linear-gradient(180deg, black, #052A35 50%, #0A5369);
`;

const HeaderText = styled.h3`
    /* border: 1px red dashed; */
    font-family: 'Montserrat', sans-serif;
    font-size: 2.2em;
    font-weight: 700;
    margin-top: 40px;
    color: white;
    line-height: 40px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.4em;
    text-align: center;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    text-align: center;
    align-self: center;
    max-width: 80%;
    font-size: 2em;
  `}
`;

const SubHeaderText = styled.h3`
    /* border: 1px red dashed; */
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-top: 40px;
    color: white;
    line-height: 40px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.4em;
    text-align: center;
    align-self: center;
  `}
  ${media.between("medium", "large")`
    text-align: center;
    align-self: center;
  `}
`;

const BtnSurvey = styled.button`
    padding: 12px;
    outline: none;
    width: 250px;
    height: 60px;
    color: black;
    background: #6EFAFF;
    border: 2px solid #6EFAFF;
    border-radius: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 18px;
    margin-top: 40px;
    align-self: center;
    z-index: 1;
  ${media.lessThan("medium")`
    margin-bottom: 80px;
  `}
  ${media.between("medium", "large")`
    align-self: center;
  `}
`;

const ButtonContainer = styled.div`
    /* border: 1px dashed red; */
    margin-top: 10px;
    width: auto;
    align-self: center;
    justify-self: center;
  ${media.lessThan("medium")`
`}
  ${media.between("medium", "large")`
`}
`;


export default class SignUp extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            buttonActive: false,
          startSurvey: false
        }
      }
    
      componentDidMount() {
        Aos.init({ duration: 2000 });
        TagManager.initialize(tagPageVisit)
      }

    
      openForm = (e) => {
        this.setState({ playing: false });
        this.setState({ startSurvey: true }, () => {
          setTimeout(() => { this.setState({ startSurvey: false }) }, 2000);
        });
      }

  render() {
    return (
      <Grid>
        <HeaderText>Sign up for a 3 month demo @ $1000/building </HeaderText>
        <SubHeaderText>Sign up for demo today</SubHeaderText>
        <VideoContainer/>

        <SurveyContainer startSurvey={this.state.startSurvey} />
      </Grid>
    );
  }
}

const tagPageVisit = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'yama_signup_page_visit'
  }
}

{/* <ButtonContainer>
<BtnSurvey onClick={this.openForm}>Sign Up</BtnSurvey>
</ButtonContainer> */}