import React, { Component } from "react";
import styled from "styled-components";
import AboutLanding from "./../About/AboutLanding";
import MissionVision from "./../About/MissionVision";
import BannerPossibility from "./../About/BannerPossibility";
import BannerOrigins from "./../About/BannerOrigins";
import BannerSolution from "./../About/BannerSolution";
import Team from "./../About/Team";

const Grid = styled.div`
  display: grid;
  overflow: hidden;
  grid-template: auto auto auto auto auto auto / 100vw;
`;

const BackgroundColor = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    background: white;
    z-index: -1;
`;

export default class About extends Component {
  render() {
    return (
      <Grid>
        <BackgroundColor />
        <AboutLanding/>
        <MissionVision/>
        <BannerPossibility/>
        <BannerOrigins/>
        <BannerSolution/>
        <Team/>
      </Grid>
    );
  }
}
