import React, { Component } from 'react';
import styled from "styled-components";
import HomeLanding from "../Home/HomeLanding";
import BannerFuture from "./../Home/BannerFuture";
import CardsActions from "./../Home/CardsActions";
import BenefitsList from "./../Home/BenefitsList";
import CustomerType from "./../Home/CustomerType";
import TagManager from 'react-gtm-module'

const Grid = styled.div`
  display: grid;
  grid-template: 600px auto auto auto auto/ 1fr;
  overflow: hidden;
`;

const BackgroundColor = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    background: linear-gradient(180deg, black, #052A35 50%, #0A5369);
    z-index: -1;
`;

export default class HomeLayout extends Component {

    componentDidMount(){
        TagManager.initialize(tagPageVisit)
      }

    render() {
        return (
            <Grid>
                    <BackgroundColor/>
                    <HomeLanding/>
                    <BannerFuture/>
                    <CardsActions/>
                    <BenefitsList/>
                    <CustomerType/>
            </Grid>
        )
    }
}

const tagPageVisit = {
    gtmId: global.config.gtmId,
    dataLayer: {
        tag: 'yama_home_page_visit'
    }
  }