import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import ReactPlayer from 'react-player'
import SurveyContainer from './SurveyContainer';
import TagManager from 'react-gtm-module'

const Grid = styled.div`
    /* border: 1px red dashed; */
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  ${media.lessThan("medium")`
    /* height: 200px; */
  `}
  ${media.between("medium", "large")`
    /* height: 400px; */
  `}
  ${media.greaterThan("large")`
  `}
`;

const ReactPlayerContainer = styled.div`
    display: flex;
    justify-content: center;
    /* margin-top: 100px; */
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const BtnSurvey = styled.button`
    padding: 12px;
    outline: none;
    width: 250px;
    height: 60px;
    color: black;
    background: #6EFAFF;
    border: 2px solid #6EFAFF;
    border-radius: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    /* align-self: center; */
    z-index: 1;
  ${media.lessThan("medium")`
    margin-bottom: 80px;
  `}
  ${media.between("medium", "large")`
    align-self: center;
  `}
`;

const ButtonContainer = styled.div`
    /* border: 1px dashed red; */
    margin-top: 10px;
    width: auto;
    align-self: center;
    justify-self: center;
    margin-bottom: 40px;
  ${media.lessThan("medium")`
`}
  ${media.between("medium", "large")`
`}
`;



// https://github.com/cookpete/react-player

export default class VideoContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      buttonActive: false,
      playing: false,
      startSurvey: false
    }
  }

  componentDidMount() {
    Aos.init({ duration: 2000 });
  }

  onPlay = () => {
    console.log("I started")
    TagManager.initialize(tagVideoVisit)
    this.setState({ playing: true });
  }

  onPause = () => {
    this.setState({ playing: false });
  }

  playedSeconds = (e) => {
    console.log("", e.playedSeconds)
    // if (e.playedSeconds > 15) {
      this.setState({ buttonActive: true });
    // }
  }

  openForm = (e) => {
    TagManager.initialize(tagSignupVisit)
    this.setState({ playing: false });
    this.setState({ startSurvey: true }, () => {
      setTimeout(() => { this.setState({ startSurvey: false }) }, 2000);
    });
  }


  render() {
    return (
      <Grid>
        <ReactPlayerContainer>
          <ReactPlayer
            width='854px'
            height='480px'
            controls
            url='https://youtu.be/NduJznKhLL4'
            playing={this.state.playing}
            onReady={() => console.log('onReady callback')}
            onEnded={() => console.log('onEnded callback')}
            onError={() => console.log('onError callback')}
            onPause={this.onPause}
            onPlay={this.onPlay}
            onProgress={this.playedSeconds}
          />
        </ReactPlayerContainer>

        <ButtonContainer>

          {
            this.state.buttonActive ? <BtnSurvey onClick={this.openForm}>Sign Up</BtnSurvey> : null
          }
        </ButtonContainer>
        <SurveyContainer startSurvey={this.state.startSurvey} />
      </Grid>
    )
  }
}
;

const tagVideoVisit = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'yama_event_video'
  }
}

const tagSignupVisit = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'yama_event_signup'
  }
}