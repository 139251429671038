import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";

const Container = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    z-index: -1;
    margin-top: 100px;
    margin-bottom: 50px;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    grid-template: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 500px;
    padding: 30px;
    margin-top: 50px;
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
    margin-top: 100px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const Icon1 = styled.div`
    border: 5px solid black;
    height: 120px;
    width: 120px;
    border-radius: 100px;
    align-self: center;
    margin-bottom: 30px;
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    z-index: 1;
    color: black;
    line-height: 30px;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.2em;
    width: 240px;
    text-align: center;
  `}

  ${media.between("medium", "large")`
    font-size: 1.4em;
  `}
`;


const AboutText = styled.p`
    width: 400px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 12pt;
    color: black;
    line-height: 32px;
    align-self: center;
    text-align: center;
  ${media.lessThan("medium")`
    font-size: 1em;
    margin-bottom: 10px;
    width: 300px;
`}

  ${media.between("medium", "large")`
    font-size: 1.2em;
    margin-bottom: 10px;
`}
`;


export default class MissionVision extends Component {


    componentDidMount(){
            Aos.init({ duration: 2000 });
          }
      

  render() {
    return (
            <Container>
                    <TitleContainer>
                        <Icon1/>
                        <Title>The Mission</Title>
                        <AboutText>A new local phone number, specific to you, for you to use with your building’s buzzer. No landline required</AboutText>
                    </TitleContainer>
                    <TitleContainer>
                        <Icon1/>
                        <Title>The Vision</Title>
                        <AboutText>Forward buzzer calls to everyone in your home at the same time. The first person to answer is connected through to your guest.</AboutText>
                    </TitleContainer>
            </Container>
    )
  }
}
