import React, { Component } from 'react';
import styled from "styled-components";
import ContactLanding from "../Contact/ContactLanding";
import ContactForm from "../Contact/ContactForm";
import media from "styled-media-query";
import TagManager from 'react-gtm-module'

const Grid = styled.div`
    display: grid;
    /* grid-template: 1fr 2fr / 1fr; */
    overflow: hidden;
    height: 100vh;
  ${media.lessThan("medium")`
    /* grid-template: 100px auto/ 1fr; */
    `}
  ${media.between("medium", "large")`
    `}
`;

const BackgroundColor = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    background: linear-gradient(180deg, black, #052A35 50%, #0A5369);
    z-index: -1;
`;

export default class ContactLayout extends Component {

    componentDidMount(){
        TagManager.initialize(tagPageVisit)
      }

    render() {
        return (
            <Grid>
                <BackgroundColor/>
                <ContactLanding/>
            </Grid>
        )
    }
}

const tagPageVisit = {
    gtmId: global.config.gtmId,
    dataLayer: {
        tag: 'yama_contact_page_visit'
    }
  }