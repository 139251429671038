import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import ImgIconCall from "./../../images/icon_call.svg"
import ImgIconEmail from "./../../images/icon_email.svg"

const Container = styled.div`
    /* border: 1px red dashed; */
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 50px;
    justify-self: center;
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    height: 400px;
    /* width: 550px; */
    border-radius: 50px;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 60px;
    background: linear-gradient(#f0f0f0 0%, #D9D9D9 100%);
    background: #D9D9D9;
    align-self: center;
    padding-left: 105px;
    padding-right: 105px;
    align-content: center;
    ${media.lessThan("medium")`
      width: 90%;
      align-self: center;
      padding-left: 0px;
    padding-right: 0px;
    margin-top: 40px;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const CardContainer = styled.div`
    /* border: 1px green dashed; */
  display: flex;
  width: 100%;
  flex-direction: row;
  align-self: center;
  ${media.lessThan("medium")`
    justify-content: center;
  `}
`;

const HeaderText = styled.h3`
    /* border: 1px red dashed; */
    font-family: 'Montserrat', sans-serif;
    font-size: 2.4em;
    font-weight: 600;
    margin-top: 50px;
    color: white;
    line-height: 40px;
    /* max-width: 550px; */
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.8em;
    margin-top: 0px;
  `}
  ${media.between("medium", "large")`
    font-size: 2em;
    text-align: center;
    align-self: center;
  `}
`;

const TextContainer = styled.div`
    /* border: 1px red dashed; */
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 140px;
  z-index: 1;
  ${media.lessThan("medium")`
  width: auto;
  align-self: center;
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Icon = styled.img`
  height: 70px;
  width: 70px;
  margin-right: 50px;
  ${media.lessThan("medium")`
      height: 50px;
      width: 50px;
      align-self: center;
      margin-right: 30px;
    `}
`;

const Title = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: -0.01em;
  color: black;
  ${media.lessThan("medium")`
        font-size: 23px;
        margin-top: 11px;
    `}
  ${media.between("medium", "large")`
        font-size: 23px;
        margin-top: 11px;
    `}
    ${media.greaterThan("large")`
  `}
`

const Blurb = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  margin-top: 10px;
  width: 260px;
  letter-spacing: 0.04em;
  color: #333333;
  ${media.lessThan("medium")`
  width: auto;
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

export default class ContactLanding extends Component {
  render() {
    return (
            <Container>
                <HeaderText>Contact</HeaderText>
                <TitleContainer>
                  <CardContainer>
                    <Icon src={ImgIconCall} />
                    <TextContainer>
                      <Title>Call Us</Title>
                      <Blurb>1 (844) 926-2368</Blurb>
                    </TextContainer>
                  </CardContainer>
                  <CardContainer>
                    <Icon src={ImgIconEmail} />
                    <TextContainer>
                      <Title>Email</Title>
                      <Blurb>info@getyama.ca</Blurb>
                    </TextContainer>
                  </CardContainer>
                </TitleContainer>
            </Container>
    );
  }
}
