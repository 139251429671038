import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Image2 from "./../../images/Buzzer.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";


const Grid = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 0px;
    margin-bottom: 20px;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
  `}
  ${media.greaterThan("large")`
  `}
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom: 100px;
    justify-content: space-evenly;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding-bottom: 0px;
    width: 800px;
    align-self: center;
  ${media.lessThan("medium")`
    width: 300px;
  `}
  ${media.between("medium", "large")`
    align-self: center;
    width: 700px;
  `}
  ${media.greaterThan("large")`
    align-self: center;
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.2em;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 40px;
    margin-top: 100px;

    color: black;
    text-align: center;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1.6em;
    width: 240px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.8em;
  `}
`;

const AboutText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-shadow: none;
    justify-self: center;
    font-size: 14pt;
    color: black;
    line-height: 38px;
    align-self: center;
    text-align: center;
  ${media.lessThan("medium")`
    font-size: 1em;
    width: 200px;
    text-align: center;
    align-self: center;
    line-height: 28px;
`}
  ${media.between("medium", "large")`
    text-align: center;
    font-size: 1.1em;
`}
`;

const BtnContact = styled.button`
    background: #0A5369;
    /* padding: 12px; */
    margin-top: 20px;
    outline: none;
    width: 250px;
    height: 60px;
    color: white;
    border: none;
    border-radius: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer; 
    transition-duration: 0.5s;
        &:hover{
          opacity: 0.9;
          transform: scale(1.05);
        }
  ${media.lessThan("medium")`
    margin-top: 10px;
    width: 200px;
    align-self: center;
`}
  ${media.between("medium", "large")`
    margin-top: 10px;
    width: 200px;
    align-self: center;
`}
`;

const ButtonContainer = styled.div`
    align-self: center;
    width: 300px;
  ${media.lessThan("medium")`
    margin-top: 10px;
    width: 200px;
`}
  ${media.between("medium", "large")`
    margin-top: 10px;
    width: 200px;
`}
`;


export default class WhatYamaDo extends Component {

  componentDidMount() {
    Aos.init({ duration: 2000 });
  }




  render() {
    return (
      <Grid>
        <Container>
          <TitleContainer>
            <Title
              data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
            >Remote Access Management for Entire Building Portfolio</Title>
            <AboutText
              data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
            >
              Remotely manage access to an entire building portfolio from one central location.
            </AboutText>
            <ButtonContainer 
                data-aos="fade-in" 
                data-aos-duration="1000" 
                data-aos-easing="ease-in-sine"
            >
              <Link to="/tellmemore">
                <BtnContact>LEARN MORE</BtnContact>
              </Link>
            </ButtonContainer>
          </TitleContainer>
        </Container>

      </Grid>
    )
  }
}

// Image - Buzzer with code