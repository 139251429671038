import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";


const Grid = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    background: white;
    justify-content: center;
    align-content: center;
    margin-top: 80px;
    margin-bottom: 80px;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}

  ${media.between("medium", "large")`
    flex-direction: column;
  `}

  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-self: center;
    margin-top: 50px;
  ${media.lessThan("medium")`
    margin-bottom: 30px;
  `}
  ${media.between("medium", "large")`
    margin-bottom: 30px;
  `}
  ${media.greaterThan("large")`
    align-self: center;
  `}
`;

const Title = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 2.4em;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 70px;
  z-index: 1;
  color: black;
  text-align: center;
${media.lessThan("medium")`
  width: 280px;
  align-self: center;
  line-height: 30px;
  font-size: 1.4em;
`}

${media.between("medium", "large")`
  font-size: 1.8em;
`}
`;

const AboutText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    width: 900px;
    text-shadow: none;
    justify-self: center;
    font-size: 14pt;
    color: black;
    line-height: 32px;
    text-align: center;
    align-self: center;
  ${media.lessThan("medium")`
    font-size: 1em;
    width: 280px;
    text-align: center;
    align-self: center;
    line-height: 28px;
`}
  ${media.between("medium", "large")`
    font-size: 1.1em;
    width: 700px;
`}
`;

export default class BannerFuture extends Component {
  render() {
    return (
        <Grid>
          <TitleContainer
              data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
          >
            <Title>Say Goodbye to Your Entryway Headaches</Title>
            <AboutText>
                Even your light bulbs are smart these days so why can't your intercom be, too?
            </AboutText>
            <AboutText>
                Yama brings your existing dialer-intercom system into the 21st century,
                enabling a suite of modern features for your aging hardware.
                With Yama you can quickly and easily give staff, contractors, and guests access to your building
                while improving both your building's security and your management's efficiency.
            </AboutText>
          </TitleContainer>
        </Grid>
    )
  }
}
;

// Image - app wireframe in iPhone
