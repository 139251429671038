import React, { Component } from "react";
import styled from "styled-components";
import TenantLanding from "../Tenants/TenantLanding";
import VideoContainer from "../TellMeMore/VideoContainer";
import SurveyContainer from "../TellMeMore/SurveyContainer";
import TagManager from 'react-gtm-module'

const Grid = styled.div`
    display: grid;
    overflow: hidden;
    grid-template: auto / 100vw;
`;

const BackgroundColor = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    background: linear-gradient(180deg, black, #052A35 50%, #0A5369);
    z-index: -1;
`;

export default class TellMeMoreLayout extends Component {

  componentDidMount(){
    TagManager.initialize(tagPageVisit)
  }

  render() {
    return (
      <Grid>
        <BackgroundColor />
        <VideoContainer/>
        {/* <SurveyContainer/> */}

      </Grid>
    );
  }
}

const tagPageVisit = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'yama_tellmemore_page_visit'
  }
}