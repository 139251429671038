import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const HBTurq = "#6EFAFF";

const Background = styled.div`
 flex-direction: column;
 align-content: center;
 justify-content: center;
 display: flex;
 flex-direction: column;
 border-top: 1px ${HBTurq} solid;
`;

const OuterContainer = styled.div`
    display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-content: center;
  background: black;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  justify-self: center;
  align-content: center;
  /* z-index: -1; */
  background: black;
  /* padding-top: 60px; */
  /* padding-bottom: 40px; */
${media.lessThan("medium")`
  /* width: 80%; */
  flex-direction: column;
  width: auto;
  `}
  ${media.between("medium", "large")`
  /* width: 80%; */
  `}
  ${media.greaterThan("large")`
  `}
`;

const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  width: 200px;
  height: 60px;
`;

const TitleContainer = styled.div`
/* border: 1px green dashed; */
  /* grid-template: 2 / 1 / 3 / 2; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 300px;
  padding-top: 30px;
  padding-bottom: 10px;
  align-content: center;
  justify-content: center;
  ${media.lessThan("medium")`
  width: auto;
  padding: 5px;
  `}
  ${media.between("medium", "large")`
  width: 200px;
  `}
  ${media.greaterThan("large")`
  /* margin-right: 100px; */
  `}
`;

const Title = styled.h3`
font-family: 'Montserrat', sans-serif;
font-size: 1em;
font-weight: bold;
z-index: 1;
color: ${HBTurq};
margin-top: 20px;
text-align: center;
${media.lessThan("medium")`
font-size: 1em;
width: 240px;
text-align: center;
`}

${media.between("medium", "large")`
font-size: 1em;
`}
`;

const BtnLink = styled.button`
    outline: none;
    border: none;
    background: none;
    padding-left: 0px;
    text-align: center;
    ${media.lessThan("medium")`
`}

  ${media.between("medium", "large")`
`}
`;

const FooterLink = styled.p`
  /* margin-bottom: 20px; */
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 11pt;
  color: white;
  line-height: 32px;
  cursor: pointer; 
    transition-duration: 0.5s;
        &:hover{
          opacity: 0.9;
          transform: scale(1.1);
        }
  ${media.lessThan("medium")`
    font-size: 1em;
    /* width: 300px; */
    margin-bottom: 10px;
`}

  ${media.between("medium", "large")`
  font-size: 11pt;
    margin-bottom: 10px;
`}
`;

const CopyrightText = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  width: 550px;
  /* margin-bottom: 30px; */
  text-shadow: none;
  justify-self: center;
  font-size: 0.8em;
  color: #CBCBCB;
  line-height: 38px;
  align-self: center;
    text-align: center;

  ${media.lessThan("medium")`
    width: 300px;
    line-height: 28px;

`}

  ${media.between("medium", "large")`
    width: 400px;

`}
`;

export default class Footer extends Component {

  componentDidMount() {
    Aos.init({ duration: 2000 });
  }

  render() {
    return (
      <Background>
        <OuterContainer>

          <Container>
            <TitleContainer>
              <Title>MANAGERS</Title>
              <BtnLink>
                <Link to="/manager" style={{ textDecoration: 'none' }}>
                  <FooterLink>Features</FooterLink>
                </Link>
              </BtnLink>
            </TitleContainer>
            <TitleContainer>
              <Title>TENANTS</Title>
              <BtnLink>
                <Link to="/tenant" style={{ textDecoration: 'none' }}>
                  <FooterLink>Why Yama?</FooterLink>
                </Link>
              </BtnLink>
            </TitleContainer>
            <TitleContainer>
              <Title>TELL ME MORE</Title>
              <BtnLink>
                <Link to="/tellmemore" style={{ textDecoration: 'none' }}>
                  <FooterLink>How it works</FooterLink>
                </Link>
              </BtnLink>
            </TitleContainer>
          </Container>
          {/* <IconContainer>
              <SocialIcon url="http://facebook.com/spherescreative" bgColor={HBTurq} />
              <SocialIcon url="http://instagram.com/spherescreative" bgColor={HBTurq} />
            </IconContainer> */}
          <CopyrightText> Copyright © 2021 Yama Inc. All rights reserved.</CopyrightText>
        </OuterContainer>
      </Background>

    )
  }
}