import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Image2 from "./../../images/Apartment_Network.png";


const Background = styled.div`
    background: white;
    align-content: center;
`;

const Container = styled.div`
    grid-area: 4 / 1 / 5 / 2;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    ${media.lessThan("medium")`
      flex-direction: column;
    `}
    ${media.between("medium", "large")`
      flex-direction: column;
    `}
    ${media.greaterThan("large")`
      flex-direction: row;
    `}
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    margin-bottom: 80px;
  ${media.lessThan("medium")`
    align-self: center;
  `}
  ${media.between("medium", "large")`
    align-self: center;
  `}
  ${media.greaterThan("large")`
    margin-right: 100px;
  `}
`;

const Title = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    z-index: 1;
    color: black;
    line-height: 30px;
    margin-bottom: 10px;
    margin-top: 30px;
  ${media.lessThan("medium")`
    align-self: center;
    font-size: 1.2em;
    width: 240px;
    text-align: center;
  `}
  ${media.between("medium", "large")`
    font-size: 1.4em;
    align-self: center;
  `}
`;

const Title2 = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.8em;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 80px;
    line-height: 70px;
    z-index: 1;
    color: black;
    align-self: center;
    text-align: center;
  ${media.lessThan("medium")`
    margin-bottom: 80px;
    align-self: center;
    line-height: 30px;
    font-size: 1.8em;
  `}
  ${media.between("medium", "large")`
    font-size: 2em;
  `}
`;

const AboutText = styled.p`
    width: 500px;
    text-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 14pt;
    color: black;
    line-height: 32px;
    text-align: center;
  ${media.lessThan("medium")`
    justify-self: center;
    font-size: 1em;
    width: 300px;
    line-height: 28px;
    margin-bottom: 10px;
    margin-top: 0px;
  `}
  ${media.between("medium", "large")`
    font-size: 1.2em;
    margin-bottom: 10px;
    width: 600px;
    margin-top: 0px;
  `}
`;

const BenefitsImage = styled.div`
    border: 2px black solid;
    outline: none!;
    z-index: 1;
    height: 400px;
    width: 500px;
    align-self: center;
  ${media.lessThan("medium")`
    height: 206.5px;
    width: 300px;
    margin-bottom: 20px;
  `}
  ${media.between("medium", "large")`
    height: 276px;
    width: 400px;
    margin-bottom: 20px;
    align-self: center;
  `}
  ${media.greaterThan("large")`
  `}
`;


export default class TopFeatures extends Component {
  render() {
    return (
        <Background>
            <Title2>Extra Dope Custom</Title2>
                <Container>
                    <TitleContainer>
                        <BenefitsImage/>
                        <Title>Super Dope</Title>
                        <AboutText>
                        Our vision is for Yama to be THE go-to service provider for apartment building access & security across North America
                        </AboutText>
                    </TitleContainer>
                    <TitleContainer>
                        <BenefitsImage/>
                        <Title>Extra Custom</Title>
                        <AboutText>
                        Our vision is for Yama to be THE go-to service provider for apartment building access & security across North America
                        </AboutText>
                    </TitleContainer>
                </Container>
            </Background>

    );
  }
}
