import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Image2 from "./../../images/img_bannerTenants.png";
import Aos from "aos";
import "aos/dist/aos.css";


const Grid = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    background-image: url(${Image2});
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center;
    height: 581px;
    width: 100%;
  ${media.lessThan("medium")`
    flex-direction: column;
    background-size: cover;
    height: 200px;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
    height: 400px;
    background-size: cover;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
  ${media.lessThan("medium")`
    width: 100%;
    height: 200px;
  `}
  ${media.between("medium", "large")`
    width: 100%;
    height: 400px;
  `}
  ${media.greaterThan("large")`
    margin-right: 200px;
    align-self: center;
  `}
`;

export default class TenantLanding extends Component {
    
        componentDidMount(){
            Aos.init({ duration: 2000 });
          }

  render() {
    return (
        <Grid>
        </Grid>
    )
  }
}
;

// Image - app wireframe in iPhone