import React, { useEffect } from "react";
import { Reset } from "styled-reset";
import {
  Switch,
  Route,
  useLocation,
  withRouter
} from "react-router-dom";
import './config';
import TenantLayout from "./components/Layout/TenantLayout";
import ManagerLayout from "./components/Layout/ManagerLayout";
import ContactLayout from "./components/Layout/ContactLayout";
import HomeLayout from "./components/Layout/HomeLayout";
import Footer from "./components/Layout/Footer";
import AboutLayout from "./components/Layout/AboutLayout";
import TellMeMoreLayout from "./components/Layout/TellMeMoreLayout";

import BootNav from "./components/Layout/BootNav";
import BootNavNoLinks from "./components/Layout/BootNavNoLinks";
import SignUp from "./components/Layout/SignUp";

// So clicking in footer scrolls you to the top of wherever you are headed
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function App() {
  let location = useLocation();

  return (
    <div>
      <Reset />
      <ScrollToTop>

        {
          window.location.pathname == "/tellmemore" ? <BootNavNoLinks /> : <BootNav />
        }

        <Switch>
          <Route path="/tellmemore">
            <TellMeMoreLayout />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/tenant">
            <TenantLayout />
          </Route>
          <Route path="/manager">
            <ManagerLayout />
          </Route>
          <Route path="/contact">
            <ContactLayout />
          </Route>
          <Route path="/">
            <HomeLayout />
          </Route>
        </Switch>
        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;
