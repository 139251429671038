import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import * as Bootstrap from "react-bootstrap";
import "aos/dist/aos.css";


const Grid = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-bottom: 80px;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
  ${media.between("medium", "large")`
    flex-direction: column;
  `}
  ${media.greaterThan("large")`
  `}
`;

const BlackContainer = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    justify-content: center;
    align-content: center;
    background: white;
    padding-top: 30px;
    padding-right: 80px;
    padding-left: 80px;
    width: auto;
    height: auto;
    justify-self: center;
    flex-direction: column;
    border-radius: 20px;
  ${media.lessThan("medium")`
    flex-direction: column;
    padding-right: 0px;
    padding-left: 0px;
    width: 80%;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const FormContainer = styled.div`
    justify-content: center;
    align-content: center;
    align-self: center;
    display: flex;
  ${media.lessThan("medium")`
    margin-bottom: 0px;
  `}
  ${media.between("medium", "large")`
  `}
`;


const AboutText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-shadow: none;
    justify-self: center;
    font-size: 1.4em;
    color: black;
    line-height: 38px;
    align-self: center;
    text-align: center;
    margin-bottom: 30px;
  ${media.lessThan("medium")`
    font-size: 1.2em;
    width: 300px;
    line-height: 28px;
  `}
    ${media.between("medium", "large")`
    width: 430px;
  `}
`;

const BtnSend = styled.button`
    width: 500px;
    height: 50px;
    background: black;
    border: 2px solid white;
    border-radius: 30px;
    box-shadow: 0px 0px 5px white;
    margin-top: 10px;
    outline: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 1em;
    align-self: center;
    justify-self: center;
  ${media.lessThan("medium")`
    margin-left: 0px;
    width: 250px;
  `}
  ${media.between("medium", "large")`
    align-self: center;
  `}
`;


export default class ContactForm extends Component {

  constructor(props){
    super(props)

    this.state = {
      name: "",
      email: "",
      message: ""
    }
  }

  async sendContactUs() {
    console.log("Contact Us Sent")

    try {
      // let result = await fetch('https://10ww8j15w1.execute-api.us-east-2.amazonaws.com/prod/sendcontactus', {
      console.log(this.state.name, this.state.email, this.state.message)

      let result = await fetch('https://fillThisIn.com', {         // put your endpoint in here
        method: 'post',
        mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          "name": this.state.name,
          "email": this.state.email,
          "message": this.state.message
        })
      });
    } catch (e) {
      console.log('BIG ERROR', e)
    }
  }
  
  nameHandler = (event) => {
    this.setState({name: event.target.value})
    console.log(event.target.value)
  }

  emailHandler = (event) => {
    this.setState({email: event.target.value})
    console.log(event.target.value)
  }

  messageHandler = (event) => {
    this.setState({message: event.target.value})
    console.log(event.target.value)
  }



  render() {
    return (
      <Grid>
      <BlackContainer>
      <AboutText>Let's talk about how Yama can make your life easier</AboutText>

      <FormContainer>
        <Bootstrap.Form>
          <Bootstrap.Form.Group controlId="exampleForm.ControlInput1">
              <Bootstrap.Form.Control onChange={this.nameHandler} type="text" placeholder="Name" style={{fontFamily: "Montserrat, sans-serif", border: "2px black solid" }}/>
            </Bootstrap.Form.Group>
            <Bootstrap.Form.Group controlId="exampleForm.ControlInput1">
              <Bootstrap.Form.Control onChange={this.emailHandler} type="email" placeholder="name@example.com" style={{fontFamily: "Montserrat, sans-serif", border: "2px black solid" }}/>
            </Bootstrap.Form.Group>
            <Bootstrap.Form.Group controlId="exampleForm.ControlTextarea1">
              <Bootstrap.Form.Control onChange={this.messageHandler} as="textarea" rows={6} placeholder="Message" style={{fontFamily: "Montserrat, sans-serif", border: "2px black solid" }}/>
            </Bootstrap.Form.Group>
            <BtnSend type="submit" onClick={() => this.sendContactUs()}>
              SEND
            </BtnSend>
        </Bootstrap.Form>
      </FormContainer>
      </BlackContainer>
    </Grid>
    )
  }
}
;
