import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import { ReactTypeformEmbed } from 'react-typeform-embed';



export default class SurveyContainer extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.startSurvey !== false) {
      this.typeformEmbed.typeform.open();
    }
  }

  componentDidMount() {
    Aos.init({ duration: 2000 })
  }

  render() {
    return (

      <div >
        <ReactTypeformEmbed
          popup={true}
          autoOpen={false}
          url="https://yama13.typeform.com/to/PxM8Eozc"
          hideHeaders
          hideFooter
          buttonText="Go!"
          style={{ width: 0, height: 0 }}
          ref={tf => {
            this.typeformEmbed = tf;
          }}
        />

      </div>
    )
  }
}
;



