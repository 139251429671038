import React, { Component } from "react";
import styled from "styled-components";
import WhyYama from "../Tenants/WhyYama";
import TenantLanding from "../Tenants/TenantLanding";
import CustomerCards from "./../Tenants/CustomerCards";
import TagManager from 'react-gtm-module'

const Grid = styled.div`
    display: grid;
    overflow: hidden;
    grid-template: auto auto auto auto auto/ 100vw;
`;

const BackgroundColor = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    background: linear-gradient(180deg, black, #052A35 50%, #0A5369);
    z-index: -1;
`;

export default class TenantLayout extends Component {

  componentDidMount(){
    TagManager.initialize(tagPageVisit)
  }

  render() {
    return (
      <Grid>
        <BackgroundColor />
        <TenantLanding/>
        <WhyYama/>
        <CustomerCards/>
      </Grid>
    );
  }
}

const tagPageVisit = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'yama_tenant_page_visit'
  }
}
